import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
// import { Link } from 'react-router-dom';
import { ApplyTrialForm } from "component/ApplyTrialForm/applyTrialForm";
import { Grid } from "antd";
import "./trialApply.less";

const { useBreakpoint } = Grid;


const ScreenXL = () => {
  const { t } = useTranslation("pages");

  return (
    <div className="trialApplyXlContainer">
      <div className="trialApplyTitleAndImg">
        <div className="trialApplyLogoAndTitle">
          <div>
            <div className="trialApplyLogo">
              <img
                width={200}
                src="/images/Title_logo.png"
                alt="HERCULES & SecSBOM logo"
              />
            </div>
            <div className="trialApplyTitle">
              {t("binaryAnalysisFreeTrial")}
            </div>
            <div className="trialApplyDescription">
              {t("landingPageDescription")}
            </div>
            <div className="trialApplyDesList">
              <ul>
                <li>{t("noSourceRequired")}</li>
                <li>{t("sbomGenAndAnal")}</li>
                <li>{t("lisenceWarning")}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="trialApplyImg">
          <img
            width="600px"
            src="/images/SecSBOM_main0.gif"
            alt="landing img"
          />
        </div>
      </div>
      <div className="trialApplyForm">
        <ApplyTrialForm />
      </div>
    </div>
  );
};

const ScreenM = () => {
  const { t } = useTranslation("pages");

  return (
    <div className="trialApplyMContainer">
      <div className="trialApplyLogo">
        <img
          width={200}
          src="/images/Title_logo.png"
          alt="HERCULES & SecSBOM logo"
        />
      </div>
      <div className="trialApplyTitle">{t("binaryAnalysisFreeTrial")}</div>
      <div className="trialApplyDescription">{t("landingPageDescription")}</div>
      <div className="trialApplyDesList">
        <ul>
          <li>{t("noSourceRequired")}</li>
          <li>{t("sbomGenAndAnal")}</li>
          <li>{t("lisenceWarning")}</li>
        </ul>
      </div>

      <div className="trialApplyForm">
        <ApplyTrialForm />
      </div>
      <div className="trialApplyImg">
        <img width="100%" src="/images/SecSBOM_main0.gif" alt="landing img" />
      </div>
    </div>
  );
};

export const TrialApply = () => {
  const screens = useBreakpoint();
  const secBOM_Version = process.env.REACT_APP_VERSION;
  useEffect(() => {
    console.log("Current version:", secBOM_Version);
  }, [secBOM_Version]);

  return (
    <>
      <div className="trialApplyMainContianer">
        {screens.xl ? <ScreenXL /> : <ScreenM />}
        <div className="versionInfo">
          {/* <Link to="/release-notes">
           {secBOM_Version}
          </Link> */}
          <a href="/release-notes.html" target="_blank" rel="noopener noreferrer">{secBOM_Version}</a>
        </div>
      </div>
    </>
  );
};
