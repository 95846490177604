import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { AAASfetch } from "utils/fetchData";
import Captcha from "component/Captcha/captcha";
import useCaptcha from "hooks/useCaptcha";
import privacyPolicy from "utils/privacyPolicy";
import OpenNotification from "component/Notification/notification";
import ShowMessage from "component/Message/message";
import { Form, Input, Card, Button, Modal } from "antd";
import "./applyTrialForm.less";

export const ApplyTrialForm = () => {
  const { t } = useTranslation(["components", "messages"]);
  const [captchaValue, genCaptchaValue] = useCaptcha();
  const [privacyPolicyVisible, setPrivacyPolicyVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const cardStyle = {
    zIndex: 2,
    marginLeft: "30px",
    height: "max-content",
    width: "466px",
    border: "1px solid rgba(0, 0, 0, 0.06)",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "6px",
  };

  const handleSubmit = (values) => {
    setLoading(true);
    let data = {
      ...values,
      product: "secsam",
      company: "trial",
    };
    delete data.captcha;

    AAASfetch("/applyForRegistration", data).then((res) => {
      if (res?.error) {
        ShowMessage("error", res.description, 5);
      } else if (res?.result) {
        OpenNotification(
          t("success.applyTrialTitle", { ns: "messages" }),
          t("success.applyTrialMsg", { ns: "messages" }) + data.email,
          "success",
          undefined,
          null,
          undefined
        );
        form.resetFields();
      }
      setLoading(false);
    });
  };

  return (
    <Card style={cardStyle}>
      <div className="applyTrialFormTitle">{t("freeTrial")}</div>
      <Form
        layout="vertical"
        validateTrigger="onBlur"
        onFinish={handleSubmit}
        form={form}
      >
        <Form.Item
          name={"name"}
          label={t("name")}
          rules={[
            { required: true, message: t("error.empty", { ns: "messages" }) },
          ]}
        >
          <Input style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name={"companyName"}
          label={t("compName")}
          rules={[
            { required: true, message: t("error.empty", { ns: "messages" }) },
          ]}
        >
          <Input style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name={"phone"}
          label={t("phone")}
          rules={[
            { required: true, message: t("error.empty", { ns: "messages" }) },
          ]}
        >
          <Input style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name={"email"}
          label={t("email")}
          rules={[
            { required: true, message: t("error.empty", { ns: "messages" }) },
            { type: "email", message: t("error.email", { ns: "message" }) },
          ]}
        >
          <Input style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name={"captcha"}
          label={t("verification")}
          required
          rules={[
            () => ({
              validator(_, value) {
                if (value === captchaValue) {
                  return Promise.resolve();
                }
                return Promise.reject(t("error.captcha", { ns: "messages" }));
              },
            }),
          ]}
        >
          <div className="captchaContainer">
            <Input style={{ width: "100%" }} />
            <Captcha
              captchaValue={captchaValue}
              onClickReset={genCaptchaValue}
            />
          </div>
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            width: "100%",
            fontSize: "16px",
            height: "40px",
            marginTop: "8px",
          }}
          loading={loading}
        >
          {t("freeTrial")}
        </Button>
        <div className="privacyPolicyAgreement">
          <Trans
            t={t}
            i18nKey="agreePrivacyPolicy"
            components={{
              span: (
                <span
                  className="privacyPolicy"
                  onClick={() => setPrivacyPolicyVisible(true)}
                />
              ),
            }}
          ></Trans>
        </div>
        <Modal
          style={{ top: "9vh" }}
          bodyStyle={{ height: "65vh", overflowY: "auto" }}
          open={privacyPolicyVisible}
          width={800}
          title={t("privacyPolicy")}
          onCancel={() => setPrivacyPolicyVisible(false)}
          footer={
            <Button
              type="primary"
              onClick={() => setPrivacyPolicyVisible(false)}
            >
              {t("ok")}
            </Button>
          }
        >
          {privacyPolicy}
        </Modal>
      </Form>
    </Card>
  );
};
