import { capitalizeFirstLetter, generateRoutes } from "utils/formatter";

function Module(name, key, apiUrl) {
  this.name = name;
  this.route = generateRoutes(this.name);
  this.api = apiUrl || capitalizeFirstLetter(name);
  this.key = key;
  this.isChild = true;
}

export const trialApply = new Module("trial apply", "1");
export const freeTrial = new Module("free trial", "2");
export const exportReport = new Module("export report", "3");
export const apply = new Module("apply", "4");
export const paidService = new Module("paid-service", "5");
// export const applyService = new Module("paid-service", "5");
export const exportReportApply = new Module("export-paid-report", "6");