import React from "react";

const privacyPolicy = (
  <div>
    <p>
      This privacy policy will help you understand how Onward Security use and
      protects the data you provide to us when you visit and use service. We
      reserve the right to change this policy at any given time, of which you
      will be promptly updated. If you want to make sure that you are up to date
      with the latest changes, we advise you to frequently visit this page.
    </p>
    <h4>What User Data We Collect</h4>
    <p>When you use the service, we may collect the following data:</p>
    <ul>
      <li>Your IP address.</li>
      <li>Your contact information and email address.</li>
      <li>Other information such as interests and preferences.</li>
      <li>Data profile regarding your online behavior in our service.</li>
    </ul>
    <h4>Why We Collect Your Data</h4>
    <p>We are collecting your data for several reasons:</p>
    <ul>
      <li>To better understand your needs.</li>
      <li>To improve our services and products.</li>
      <li>
        To send you promotional emails containing the information we think you
        will find interesting.
      </li>
      <li>
        To contact you to fill out surveys and participate in other types of
        market research.
      </li>
    </ul>
    <h4>Safeguarding and Securing the Data</h4>
    <p>
      Onward Security is committed to securing your data and keeping it
      confidential. Onward Security has done all in its power to prevent data
      theft, unauthorized access, and disclosure by implementing the latest
      technologies and software, which help us safeguard all the information we
      collect online.
    </p>
    <h4>Data retention and deletion</h4>
    <p>
      When you upload binary files for use with our online service, we will
      temporarily store your files and send them to cloud servers for
      processing. We only use those binary files for the specific service you
      requested. After your requested service is completed, the binary files
      will be immediately eliminated from our servers. Please ensure that this
      fits your data security policy before using our service.
    </p>
    <p>
      We may store information related to you and your use of our products,
      including personal data, for a reasonable period necessary to provide you
      with our products and the purposes described in this Privacy Policy. It
      should be noted that your personal data will be stored even if you stop
      using our service.
    </p>
    <h4>Your rights</h4>
    <h3>Contact Us</h3>
    <p>
      If you have any questions or suggestions about our Privacy Policy, do not
      hesitate to contact us at{" "}
      <a href="mailto:service.onward@dekra.com">service.onward@dekra.com</a>
    </p>
  </div>
);

export default privacyPolicy;
