import React from "react";
import { Spin } from "antd";
import "./fullPageLoading.less";

function FullPageLoading() {
  return (
    <div className="spinContainer">
      <Spin size="large" />
    </div>
  );
}

export default FullPageLoading;
