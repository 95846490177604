import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // pass the i18n instance to react-i18next.
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    allowMultiLoading: true,
    fallbackLng: "en-US",
    lng: sessionStorage.getItem("lang") || "en-US",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
