import React, { useState, useContext, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AAASfetch_Apply } from "utils/fetchData";
import { Card, Grid, Button } from "antd";
import ShowMessage from "component/Message/message";
import ReportContext from "contexts/ReportContext";
import "./paidservice.less";
import "../../default.less";


const { useBreakpoint } = Grid;

export const PaidService = () => {
  const { t } = useTranslation(["pages_Apply", "components_Apply", "messages_Apply"]);

  const [searchParams] = useSearchParams();

  const token = searchParams.get("q");
  const screens = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const { setReportData } = useContext(ReportContext);

  const cardStyle = {
    width: screens.xl ? "1160px" : "728px",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "0 0 2px 2px",
  };


  const handleVerify = useCallback(() => {
    setLoading(true);

    AAASfetch_Apply("VerifyUserPaid", undefined, token).then((res) => {
      setLoading(false);

      if (res?.TrialTimeStart && res?.TrialTimeEnd) {
        ShowMessage("success", t("success.verifyUser", { ns: "messages" }), 5);

        const reportData = {
          trialTimeStart: res.TrialTimeStart,
          trialTimeEnd: res.TrialTimeEnd
        };
        setReportData(reportData);


        localStorage.setItem("reportData", JSON.stringify(reportData));

        if (res.trialLink) {
          console.log("trialLink:", res.trialLink); // 添加這行打印 trialLink
          window.open(res.trialLink, "_blank", "noopener,noreferrer");
        } else {
          ShowMessage("error", t("error.noLinkAvailable", { ns: "messages" }), 5);
        }

      } else if (res?.error) {
        ShowMessage("error", res.description, 5);
      }
    }).catch((error) => {
      ShowMessage("error", error.message, 5);
      setLoading(false);
    });
  }, [t, token, setLoading, setReportData]);


  const ApplyButton = () => (
    <div className="button">
      <Button type="primary" loading={loading} onClick={handleVerify}>
        {t("apply", { ns: "components" })}
      </Button>
    </div>
  );


  return (
    <div className="freeTrialContainer">
      <Card style={cardStyle}>
        <div className="logo">
          <img
            src="/images/Title_logo.png"
            alt="HERCULES & SecSBOM logo"
            style={{ width: "200px" }}
          />
        </div>
        <div className="title">{t("binaryAnalysisFreeTrial")}</div>
        <div className="description">{t("freeTrialDes")}</div>
        {!verified && <ApplyButton />}
      </Card>
    </div>
  );
};

