import { useState, useEffect, useCallback } from "react";
import { getRandomInt } from "utils/formatter";

function useCaptcha(charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789") {
  const [captchaValue, setCaptchaValue] = useState("");

  const genCaptchaValue = useCallback(() => {
    let value = "";
    for (let i = 0, n = charset.length; i < 4; ++i) {
      value += charset.charAt(getRandomInt(0, n - 1));
    }
    setCaptchaValue(value);
  }, [charset]);

  useEffect(() => {
    genCaptchaValue();
  }, [genCaptchaValue]);

  return [captchaValue, genCaptchaValue];
}

export default useCaptcha;
